<template>
  <div
    class="flex flex-row bg-white w-9/12 mx-auto py-8 rounded-md mobile:rounded-none mobile:flex-wrap mobile:w-full mobile:px-4 px-16 pb-20"
  >
    <div
      class="w-7/12 mobile:w-full flex flex-col justify-center mobile:text-center mobile:justify-center"
    >
      <span class="text-4xl font-extrabold  mobile:mb-3 "
        >Huvitatud? <span class="text-green">Loomulikult</span>!</span
      >
      <span class="pr-8 mobile:mb-5 mobile:pr-0 text-left"
        >Proovi Gewodot juba täna. Liitumine on lihtne, õppimine samuti. Vaid 15
        minutit ning sul on mugavalt ülevaade kõikidest Gewodo funktsioonidest.
        Lisaks saad kanda kõik oma ettevõtte tegevused üle lihtsate importidega.
        Kaarti lisama ei pea. 14 päeva Premium paketti tasuta. Baaspakett alati
        tasuta.
      </span>
    </div>
    <div class="w-5/12 mobile:w-full flex flex-col items-center justify-center">
      <router-link
        :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
        class="w-full flex items-center justify-center"
      >
        <button
          class="w-2/3 bg-green text-white hover:bg-white hover:shadow-outline hover:text-green text-3xl py-4 font-medium"
        >
          Proovi TASUTA
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
