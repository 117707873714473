<template>
  <div class="flex w-6/12 mobile:w-full flex-col p-3">
    <div
      class="bg-white rounded-xl shadow mobile:shadow-none p-3 flex-col flex"
    >
      <div class="flex mb-3 mobile:flex-wrap">
        <div
          class="w-4/12 mobile:w-7/12 flex justify-center items-center mobile:mx-auto"
        >
          <img :src="imageUrl" class="h-30 w-30 rounded-full shadow-lg" />
        </div>
        <div
          class="w-8/12 mobile:w-full flex flex-col justify-between py-3 px-3 mobile:items-center"
        >
          <span class="font-semibold text-lg ">{{ name }}</span>
          <span class="font-medium text-lg ">{{ title }}</span>
          <a :href="'mailto:' + email" class="hover:text-green">{{ email }}</a>
          <a :href="linkedin" target="_blank"
            ><button class="linkedin-button new-button-green">
              <span class="icon typcn typcn-social-linkedin"> </span>
              <span class="label">LinkedIn</span>
            </button></a
          >
        </div>
      </div>
      <div class="flex px-3 mobile:mb-3">
        <span>{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      default: "asd",
      type: String
    },
    title: {
      default: "asd",
      type: String
    },
    email: {
      default: "asd@asd.ee",
      type: String
    },
    text: {
      default: "asd",
      type: String
    },
    imageUrl: {
      default: "https://picsum.photos/150",
      type: String
    },
    linkedin: {
      default: "https://linkedin.com",
      type: String
    },
    buttonText: {
      default: "See LinkedIn",
      type: String
    }
  }
};
</script>

<style lang="scss">
.linkedin-button {
  @apply w-2/3;
}
@media screen and (max-width: 1700px) {
  .linkedin-button {
    @apply w-full;
  }
}
</style>
