<template>
  <div class="wrapper">
    <div class="container-full flex-col">
      <about-us-header></about-us-header>
      <partners :text="true"></partners>
      <our-values></our-values>
      <in-numbers></in-numbers>
      <achievements></achievements>
      <cta></cta>
      <our-team></our-team>
      <faq></faq>
    </div>
  </div>
</template>

<script>
import AboutUsHeader from "@/components/public/about-us/AboutUsHeader";
import OurValues from "@/components/public/about-us/OurValues";
import InNumbers from "@/components/public/landing/InNumbers";
import CTA from "@/components/public/about-us/CTA";
import FAQ from "@/components/public/FAQ";
import Achievements from "@/components/public/about-us/Achievements";
import OurTeam from "@/components/public/landing/OurTeam";
import Partners from "@/components/public/landing/Partners";

export default {
  components: {
    AboutUsHeader,
    OurValues,
    faq: FAQ,
    cta: CTA,
    Achievements,
    OurTeam,
    Partners,
    InNumbers
  }
};
</script>
