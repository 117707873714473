<template>
  <div class="flex w-full mx-auto items-center">
    <div class="flex w-3/4 mx-auto px-16 mobile:w-full mobile:px-4">
      <div class="flex w-6/12 mobile:hidden py-12 justify-start items-center">
        <img
          src="/header/about_us.png"
          class="w-auto flex-shrink-0 flex-grow-0 about-us-header-image"
        />
      </div>
      <div
        class="flex w-6/12 py-12 mobile:px-2 flex-col mobile:w-full mobile:text-center justify-center mobile:items-center pl-3"
      >
        <span
          class="text-5xl mobile:text-3xl text-green font-bold  leading-none mb-2"
          >Miks me loome
        </span>
        <span
          class="text-5xl mobile:text-3xl text-green font-bold  mb-3 leading-none"
          >Gewodot?</span
        >
        <div
          class="flex w-full flex-col rounded-md mobile:items-center mobile pr-5 mobile:px-2 text-left"
        >
          <p class="mb-3 leading-relaxed">
            Üle 95% väikestest ja keskmistest ettevõtetest on
            vähedigitaliseeritud. Enamik tarkvara turul on aga liiga keeruline,
            kallis ning suunatud suurematele ettevõtetele, et neile kasulik
            olla. See vähendab omakorda oluliselt nende konkurentsivõimet.<br />
            <span class="font-semibold"
              >Gewodo sündis selleks, et neid probleeme lahendada.</span
            >
          </p>
          <span class="mb-3 "
            >Gewodo on lihte ja mugav digitaalne tööhalduse keskkond, mis on
            suunatud mikro- ja väikeettevõtjatest teenusepakkujatele. Gewodo
            muudab tööprotsessi kliendisõbralikuks ja efektiivseks, aidates
            digitaalsete võimete osas väiksematel ettevõtetel suurtele järgi
            jõuda.
            <span class="font-semibold"
              >Meie siht on luua maailm, kus iga ettevõte, olenemata suurusest,
              saab oma töö digitaliseerida lihtsalt, mugavalt ja soodsalt.</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: "",
      success: false,
      error: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.loading = true;
        const data = { sender_email: this.email, type: "user" };
        this.apiRequest("core/email/interest/", "post", false, data)
          .then(
            res => {
              this.$store.state.loading = false;
              if (res.status === 201) {
                this.success = true;
              } else {
                this.error = true;
              }
            },
            err => {
              this.$store.state.loading = false;
              if (err) {
                this.error = true;
              }
            }
          )
          .catch(err => {
            this.$store.state.loading = false;
            if (err) {
              this.error = true;
            }
          });
      }
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>

<style lang="scss">
.about-us-header-image {
  max-height: 400px;
}
</style>
