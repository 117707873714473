<template>
  <div
    class="flex flex-col mb-5 w-7/12 mx-auto mobile:w-full pb-20 px-16 mobile:px-4"
  >
    <h1 class="text-center mb-4 text-5xl mobile:text-4xl font-semibold">
      Meie tiim
    </h1>
    <div class="flex flex-wrap w-full">
      <our-team-card
        name="Asso Kasuk"
        title="CEO"
        email="asso@gewodo.com"
        text="BA majandus, MA ettevõtluse ja tehnoloogia juhtimine (omandamisel) - Tartu Ülikool. 5 aastat kliendi-, turunduse- ja projektijuhtimise kogemust."
        image-url="/proto-front/team/asso.png"
        button-text="Vaata LinkedIn-i"
        linkedin="https://www.linkedin.com/in/asso-kasuk-52b068152/"
      ></our-team-card>
      <our-team-card
        name="Erik Enden"
        title="CTO"
        email="erik@gewodo.com"
        text="5 aastat veebiarenduse, front-end arenduse ning erilahenduste loomise kogemust."
        image-url="/proto-front/team/erik.png"
        button-text="Vaata LinkedIn-i"
        linkedin="https://www.linkedin.com/in/erik-enden-6b931416a/"
      ></our-team-card>
      <our-team-card
        name="Jaagup Selli"
        title="Arendaja"
        email="jaagup@gewodo.com"
        text="Neli aastat back-end arenduse kogemust. BA IT (omandamisel) - TTÜ."
        image-url="/proto-front/team/jaagup.png"
        button-text="Vaata LinkedIn-i"
        linkedin="https://www.linkedin.com/in/jaagup-selli/"
      ></our-team-card>
    </div>
  </div>
</template>

<script>
import OurTeamCard from "@/components/public/about-us/OurTeamCard";
export default {
  components: {
    OurTeamCard
  }
};
</script>
