<template>
  <div class="w-9/12 mx-auto flex flex-col items-center bg-white mb-16 py-16">
    <div class="mx-auto flex flex-col items-center w-full">
      <span
        class="mobile:text-center mb-12 text-5xl mobile:text-4xl font-semibold"
      >
        Gewodo numbrites
      </span>
      <div class="flex w-full mobile:flex-wrap items-center">
        <div
          class="flex-col flex w-1/3 mobile:w-full mobile:border-0 p-8 items-center border-r border-green"
        >
          <div
            class="flex flex-col bg-green rounded-md p-3 items-center text-white leading-none"
          >
            <span class="text-6xl mobile:text-5xl font-extrabold tracking-wider"
              >100+</span
            >
          </div>
          <span class="font-semibold text-2xl mobile:text-center"
            >kasutajat</span
          >
        </div>
        <span class="hidden mobile:flex heading-underline-small"></span>
        <div
          class="flex-col flex w-1/3 mobile:w-full mobile:border-0 p-8 items-center border-r border-l border-green"
        >
          <div
            class="flex flex-col bg-white rounded-md p-3 items-center text-white leading-none"
          >
            <span
              class="text-6xl mobile:text-5xl font-extrabold tracking-wider text-green"
              >20+</span
            >
          </div>
          <span class="font-semibold text-2xl">partnerit</span>
        </div>
        <span class="hidden mobile:flex heading-underline-small"></span>
        <div
          class="flex-col flex w-1/3 mobile:w-full p-8 items-center border-l border-green mobile:border-0"
        >
          <div
            class="flex flex-col rounded-md p-3 items-center text-white leading-none"
          >
            <span class="text-6xl font-extrabold tracking-wider text-green"
              >15+</span
            >
          </div>
          <span class="font-semibold text-2xl text-center"
            >säästetud tundi kuus</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
