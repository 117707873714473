<template>
  <div
    class="flex flex-col items-center w-9/12 mx-auto mobile:w-full mobile:px-4 px-16 pb-16"
  >
    <span class="text-5xl font-semibold mb-12 mobile:text-center"
      >Meie saavutused</span
    >
    <div class="flex w-full mb-8 mobile:mb-16 items-center mobile:flex-col">
      <div
        class="flex w-2/12 mobile:w-full items-center justify-start mobile:justify-center mobile:mb-4"
      >
        <img src="/achievements/incubator.png" class="rounded-xl h-48" />
      </div>
      <div class="flex w-10/12 mobile:w-full flex-col pl-4 mobile:pl-0">
        <span class="font-semibold text-xl mb-2 mobile:text-center"
          >Gewodo on osa Tehnopoli Startup Inkubaatori Batch03-st</span
        >
        <p>
          Gewodo valiti ühe iduettevõttena tunnustatud Tehnopoli Startup
          Inkubaatori kevadisse inkubatsiooniprogrammi. Inkubatsiooniprogramm
          aitab meil veelgi paremini arendada oma toodet ja pakkuda klientidele
          võimalikult head teenust. Tutvu lähemalt programmiga
          <a
            href="https://www.startupincubator.ee"
            target="_blank"
            class="hover:text-green duration-100"
            >siit</a
          >.
        </p>
      </div>
    </div>
    <div class="flex w-full mb-8 items-center  mobile:flex-col">
      <div class="flex w-10/12 flex-col mobile:w-full mobile:order-2">
        <span class="font-semibold text-xl mb-2 mobile:text-center"
          >Gewodo sai Prototroni võistlusel TOP30 tiimi sekka</span
        >
        <p>
          Meie tööhalduskeskkond valiti Prototroni ideede võistluse sügisvoorus
          500-st kandidaadist parima 30 idee hulka. Lisainformatsiooni
          Prototroni võistluse kohta
          <a
            href="https://prototron.ee"
            target="_blank"
            class="hover:text-green duration-100 font-medium"
            >siit</a
          >.
        </p>
      </div>
      <div
        class="flex w-2/12 items-center justify-end mobile:w-full mobile:justify-center mobile:order-1 mobile:mb-4"
      >
        <img src="/achievements/prototron.png" class="rounded-xl h-48" />
      </div>
    </div>
    <div class="flex w-full mb-8 items-center  mobile:flex-col">
      <div
        class="flex w-2/12 items-center justify-start mobile:w-full mobile:justify-center mobile:mb-4"
      >
        <img src="/achievements/basecamp.png" class="rounded-xl h-48" />
      </div>
      <div class="flex w-10/12 flex-col pl-4 mobile:w-full mobile:pl-0">
        <span class="font-semibold text-xl mb-2 mobile:text-center"
          >Gewodo valiti Basecamp Spring 2021 võistlusel 30 osaleja hulka</span
        >
        <p>
          Gewodo valiti Superangeli ja Garage48 korraldataval Basecamp Spring
          2021 hackathonil ligi 100-st kandidaadist üle terve Euroopa 30 parima
          sekka. Gewodo tiim sai võistluselt suurepärase kogemuse ning läbi
          saadud mentorluse, tagasiside ja testide, saame pakkuda teile veelgi
          paremat teenust. Tutvu lähemalt võistlusega
          <a
            href="https://www.superangel.io/basecamp"
            target="_blank"
            class="hover:text-green duration-100 font-medium"
            >siit</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
