<template>
  <div
    class="flex flex-col w-9/12 mobile:w-full mobile:px-4 mx-auto pt-16 valuebox rounded-md mobile:rounded-none bg-white mb-5"
  >
    <span class="self-center mb-12 text-5xl font-semibold mobile:text-center"
      >Me teame sinu soove</span
    >
    <div class="flex w-full mx-auto mobile:flex-col">
      <div
        class="w-1/3 flex flex-col mobile:px-0 px-16 mobile:w-full mobile:mb-4"
      >
        <div class="flex bg-offwhite rounded-full p-0 shadow mb-4">
          <div
            class="bg-green p-2 rounded-full leading-none font-semibold text-white w-8 h-8 flex items-center justify-center"
          >
            1
          </div>
          <div class="flex items-center pl-2 font-semibold">
            Ehitatud koostööna
          </div>
        </div>
        <div class="flex">
          Oleme Gewodo loonud esimesest koodireast alates koostöös
          väikeettevõtetest teenusepakkujatega. Oleme arvesse võtnud suuremaid
          probleeme ja murekohti ning hinnastanud Gewodo nii, et seda saaks
          kasutada iga ettevõte, olenemata suurusest.
        </div>
      </div>
      <div
        class="w-1/3 flex flex-col px-16 mobile:px-0  mobile:w-full mobile:mb-4"
      >
        <div class="flex bg-offwhite rounded-full p-0 shadow mb-4">
          <div
            class="bg-green p-2 rounded-full leading-none font-semibold text-white w-8 h-8 flex items-center justify-center"
          >
            2
          </div>
          <div class="flex items-center pl-2 font-semibold">
            Lihtne ja mugav
          </div>
        </div>
        <div class="flex">
          Meie eesmärk on see, et iga ettevõtja saaks hakata Gewodot kasutama 15
          minutiga. Seetõttu oleme loonud Gewodo võimalikult selge, lihtsa ja
          arusaadavana. Gewodo rakendamiseks ei ole vaja kuulata tunde
          õpetusvideoid või istuda konsultandiga kõnedes - lihtsalt registreeri
          ja kasuta!
        </div>
      </div>
      <div class="w-1/3 flex flex-col px-16 mobile:px-0  mobile:w-full">
        <div class="flex bg-offwhite rounded-full p-0 shadow mb-4">
          <div
            class="bg-green p-2 rounded-full leading-none font-semibold text-white w-8 h-8 flex items-center justify-center"
          >
            3
          </div>
          <div class="flex items-center pl-2 font-semibold">
            Varasem kogemus
          </div>
        </div>
        <div class="flex">
          Gewodo meeskonnas on väärt kogemus väikse teenusettevõtte
          juhtivtasandilt. Sealhulgas siis nii suurte, kui väikeste projektide
          juhtimiselt. Meie meeskond teab hästi, mis probleemid võivad
          ettevõtetel tekkida, kust säästa aega ja kuidas saada projektidest
          maksimumi.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>

<style lang="scss">
.value-card {
  @apply flex flex-col rounded-md items-center shadow-lg w-full p-12 h-full;
  .value-card-title {
    @apply text-2xl  font-bold;
  }
}
</style>
